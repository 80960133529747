.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.navbar {
  align-items: center;
  width: 40%;
  justify-content: center;
  background-color: white;
  padding-left: 20%;
  display: flex;
  position: absolute;
  top: calc(100vh - var(--footer-height));
  bottom: 0;
  right: 0;
}

.bottom-tab-nav {
  border: solid 3px #EBEBEB;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  box-shadow: #bfbfbf;
  align-items: center;
  justify-content: center;
  padding-left: 20%;
}

.nav-link,
.bottom-nav-link {
  color: #bfbfbf;
  align-items: center;
  justify-content: center;
}

.bottom-nav-link.active {
  color: #ff69b4;
  align-items: center;
  justify-content: center;
}

.bottom-tab-label {
  font-size: 12px;
  align-items: center;
  justify-content: center;
}

.collapsible .content {
    padding: 6px;
    font-size: 14px;
    color: #ff69b4;
}

.collapsible .header {
    padding: 6px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    border-bottom: 2px solid rgb(220, 220, 220);
    height: 80px;
    color: #ff69b4;
    font-family: sans-serif;
}

.collapsible .header .title {
    font-weight: 600;
    font-family: sans-serif;
    font-size: 18px;
}

.collapsible .header .icon {
  padding-top: 5px;
}

.preferences label {
    display: block;
    padding-bottom: 4px;
    font-family: sans-serif;
    font-size: 17px;
}
